<script setup>
import { RouterView } from "vue-router";
</script>

<template>
  <preloader v-if="isLoading"></preloader>
  <RouterView />
</template>

<script>
import Preloader from '@/components/Preloader.vue'; // Pastikan path yang sesuai

export default {
  components: {
    Preloader,
  },
  data() {
    return {
      isLoading: false, // Ganti nilai menjadi `false` ketika data sudah dimuat.
    };
  },
  // ...
};
</script>

<!-- <template>
  <body class="bg-red-300">
    <nav>
      <p class="font-extrabold text-red-700">Hellloo</p>
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link>
    </nav>
    <router-view/>
  </body>
</template> -->

<style>
#app {
  font-family: Roboto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
}
</style>
