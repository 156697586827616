<script setup>
import { useDark, useToggle } from "@vueuse/core";

const isDark = useDark();
const toggleDark = useToggle(isDark);
</script>

<template>
  <main
    class="pt-2 bg-gradient-to-br from-gray-100 from-50% via-[#36CFDC] to-blue-400 dark:from-[#283048] dark:to-[#014F93] min-h-screen"
  >

  <!-- Top Navbar -->
  <section class="mt-16 mx-20 header">
    <div class="m-4 h-12 flex flex-row">
    <div class="basis-10/12">
      <router-link to="/"><img 
        :src="isDark ? require('../assets/img/logo-white.png') : require('../assets/img/logo-ffa.png')"
        class="w-14 m-1"></router-link>
    </div>
    <div class="basis-2/12 flex flex-row header-basis2">
      <a href="" @click="toggleDark()">
        <div class="w-10 h-10 relative group">
          <div class="absolute inset-0 bg-cover bg-center z-0 mt-1 group-hover:-mt-0 group-hover:ml-1 group-hover:animate-pulse">
            <svg v-if="isDark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="w-6 h-6 stroke-white fill-current text-white group-hover:text-white float-right mt-3 ml-15">
              <path stroke-linecap="round" stroke-linejoin="round" d="M12 3v2.25m6.364.386l-1.591 1.591M21 12h-2.25m-.386 6.364l-1.591-1.591M12 18.75V21m-4.773-4.227l-1.591 1.591M5.25 12H3m4.227-4.773L5.636 5.636M15.75 12a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0z" />
            </svg>   
            <svg v-else xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="w-6 h-6 fill-current text-[#36CFDC] group-hover:text-[#075C9B] float-right mt-3 ml-15">
              <path fill-rule="evenodd" d="M9.528 1.718a.75.75 0 01.162.819A8.97 8.97 0 009 6a9 9 0 009 9 8.97 8.97 0 003.463-.69.75.75 0 01.981.98 10.503 10.503 0 01-9.694 6.46c-5.799 0-10.5-4.701-10.5-10.5 0-4.368 2.667-8.112 6.46-9.694a.75.75 0 01.818.162z" clip-rule="evenodd" />
            </svg>           
          </div>
          <div class="opacity-0 group-hover:opacity-100 duration-300 absolute z-10 bg-gradient-to-br from-[#075C9B] to-[#36CFDC] dark:from-white dark:to-[#36CFDC] bg-clip-text text-transparent font-semibold ml-4 mt-9" style="font-size: 13px;">{{ isDark ? "Light" : "Dark" }}</div>
        </div>
      </a>
      <router-link to="/beranda" class="font-medium mt-3 ml-12 mr-11">
        <div class="relative group">
          <div class="absolute inset-0 z-0 text-[#36CFDC] dark:text-white group-hover:text-[#075C9B] dark:group-hover:text-zinc-50  text-lg -ml-5 mt-1 group-hover:mt-0 group-hover:font-extrabold">IND</div>
          <div class="opacity-0 group-hover:opacity-100 duration-300 absolute z-10 bg-gradient-to-br from-[#075C9B] to-[#36CFDC] dark:from-white dark:to-[#36CFDC] bg-clip-text text-transparent font-semibold mt-6 -ml-7" style="font-size:12px">Indonesia</div>
        </div>
      </router-link>
        <router-link to="/listmenu">
          <div class="w-0 h-8 relative group">
            <div class="absolute inset-0 bg-cover bg-center z-0 mt-1 group-hover:mt-0 w-10 h-10">
              <img 
              :src="isDark ? require('../assets/img/menu-white.png') : require('../assets/img/menu.png')"
              alt="">
            </div>
            <div class="opacity-0 group-hover:opacity-100 duration-300 absolute z-10 bg-gradient-to-br from-[#075C9B] to-[#36CFDC] dark:from-white dark:to-[#36CFDC] bg-clip-text text-transparent font-semibold mt-9 ml-1" style="font-size: 13px;">
              Menu
            </div>
          </div>
        </router-link>
    </div>
    </div>
  </section>
  <!-- Bottom Navbar -->
  
  <!-- Top Section -->
  <div class="home" style="margin-bottom: 43px;">
    <section class="mt-24 w-3/4 m-auto flex sectionn">
      <div class="basis-2/3 p-3 section-home1">
        <p class="text-lg font-light text-[#075C9B] dark:text-[#36CFDC]">Hey Welcome ! I'm</p>
        <p class="font-bold nama-lengkap dark:text-white">Farah Fitriavida Arifin</p>
        <div class="bgbg bg-gradient-to-br from-[#075C9B] from-10% via-[#36CFDC] to-transparent rounded-md">
          <p class="p-2 font-semibold text-white mt-2 mb-2 ml-2 text-2xl">
            <span class="typed-text huruf">{{typeValue}}</span>
            <span class="inline-block w-0.5 bg-white cursir huruf" :class="{'typing' : typeStatus}">&nbsp;</span>
          </p>
        </div>
        <p class="text-lg text-indigo-300 animate-pulse opacity-100 font-semibold ml-9 letss dark:text-zinc-200">Let's explore my world 🙌</p>
        <div class="mt-2 flex moree">
          <router-link to="/about" class="flex w-30 p-2 rounded-md text-[#075C9B] font-light mr-5 hover:underline dark:text-[#36CFDC]">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="w-5 h-5 fill-current dark:fill-[#36CFDC]">
              <path fill-rule="evenodd" d="M15.75 2.25H21a.75.75 0 01.75.75v5.25a.75.75 0 01-1.5 0V4.81L8.03 17.03a.75.75 0 01-1.06-1.06L19.19 3.75h-3.44a.75.75 0 010-1.5zm-10.5 4.5a1.5 1.5 0 00-1.5 1.5v10.5a1.5 1.5 0 001.5 1.5h10.5a1.5 1.5 0 001.5-1.5V10.5a.75.75 0 011.5 0v8.25a3 3 0 01-3 3H5.25a3 3 0 01-3-3V8.25a3 3 0 013-3h8.25a.75.75 0 010 1.5H5.25z" clip-rule="evenodd" />
            </svg>
            &nbsp;<span class="more2 dark:text-white">more about me</span>
          </router-link>
          <router-link to="/projects" class="flex w-30 p-2 rounded-md text-[#075C9B] hover:underline font-light dark:text-[#36CFDC]">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="w-5 h-5 fill-current dark:text-[#36CFDC]">
              <path fill-rule="evenodd" d="M15.75 2.25H21a.75.75 0 01.75.75v5.25a.75.75 0 01-1.5 0V4.81L8.03 17.03a.75.75 0 01-1.06-1.06L19.19 3.75h-3.44a.75.75 0 010-1.5zm-10.5 4.5a1.5 1.5 0 00-1.5 1.5v10.5a1.5 1.5 0 001.5 1.5h10.5a1.5 1.5 0 001.5-1.5V10.5a.75.75 0 011.5 0v8.25a3 3 0 01-3 3H5.25a3 3 0 01-3-3V8.25a3 3 0 013-3h8.25a.75.75 0 010 1.5H5.25z" clip-rule="evenodd" />
            </svg>
            &nbsp;<span class="more2 dark:text-white">see my project</span>
          </router-link>
        </div>
      </div>
      <div class="basis-1/3 section-home2">
        <img src="../assets/img/farah-cartoon.jpeg" alt="" class="z-[9999] ml-9 border-4 border-indigo-300 dark:border-zinc-100 rounded-full drop-shadow-2xl w-96 animate-bounce" style="animation-duration: 3s;">
        <div class="shadow relative ml-32 w-32 z-[0] bg-gradient-to-br from-transparent from-40% to-slate-600 dark:from-transparent dark:to-white h-16 -mt-10 animate-pulse" style="border-radius: 50%;"></div>
      </div>      
    </section>
  </div>
  <!-- Bottom Section -->
  </main>
</template>

<!-- <template>
  <div class="home">
    <img alt="Vue logo" src="../assets/logo.png">
    <HelloWorld msg="Welcome to Your Vue.js App"/>
  </div>
</template> -->

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: 'HomeView',
  components: {
    // HelloWorld
  },
  data() {
    return {
      typeValue     : ' ',
      typeStatus    : false,
      typeArray     : ['Software Engineer','Front-End Developer','Back-End Developer','Fullstack Developer', 'Tech Enthusiast'],
      typingSpeed   : 200,
      erasingSpeed  : 100,
      newTextDelay  : 2000,
      typeArrayIndex: 0,
      charIndex     : 0
    };
  },
  methods: {
    typeText(){
      if(this.charIndex < this.typeArray[this.typeArrayIndex].length){
        if(!this.typeStatus){
        this.typeStatus = true;
        }

        this.typeValue += this.typeArray[this.typeArrayIndex].charAt(this.charIndex);
        this.charIndex += 1;

        setTimeout(this.typeText, this.typingSpeed);
      }else{
        this.typeStatus = false;
        setTimeout(this.eraseText, this.newTextDelay);
      }
    },
    eraseText() {
      if (this.charIndex > 0) {
        if (!this.typeStatus) {
          this.typeStatus = true;
        }

        this.typeValue = this.typeArray[this.typeArrayIndex].substring(0, this.charIndex - 1);
        this.charIndex -= 1;
        setTimeout(this.eraseText, this.erasingSpeed);
      } else {
        this.typeStatus = false;
        this.typeArrayIndex += 1;
        if (this.typeArrayIndex >= this.typeArray.length)
          this.typeArrayIndex = 0;

        setTimeout(this.typeText, this.typingSpeed + 1000);
      }
  }},
  created() {
    setTimeout(this.typeText, this.newTextDelay + 200);
  },
}
</script>

<style scoped>
.cursir{
animation: cursorBlink 1s infinite;
}

.cursir.typing {
animation: none;
}
.bgbg{
  width:45%
}

@keyframes cursorBlink{
49% { background-color: white}    
50% { background-color: transparent ;}
99% { background-color: transparent ;}
}

.nama-lengkap{
  font-size: 3.75rem/* 60px */;
  line-height: 1;
}

/* Contoh penggunaan media queries */
@media (max-width: 360px) {
  .shadow{
    display:none;
  }
  .bgbg{
    width: 200px;
  }
  .letss{
    font-size: 10%;
    width: 200px;
  }

  .nama-lengkap{
    font-size: 0.4rem;
  }
  .moree{
    font-size:10%;
  }
}
@media (max-width: 768px) {
  body{
    width:100%
  }
  .header{
    width:80%;
    margin-left:0px
  }
  .moree{
    font-size:70%;
  }
  .more2{
    margin-top:5px
  }
  .sectionn{
    margin-left:20px
  }
  .section-home1{
    width:70%;
  }
  .section-home2{
    width:55%;
    margin-left:-30px
  }
  .section-home2 > div{
    margin-left:150px
  }
  .huruf{
    font-size:70%;
    margin:0px
  }
  .nama-lengkap{
    font-size:60px
  }
  .letss{
    margin-left:0px;
    font-size:15px
  }
  .moree{
    font-size:70%;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  /* Aturan CSS untuk tampilan tablet */
  body{
    width:100%;
  }
  .header{
    width:65%
  }
  .letss{
    margin-left:0px;
    font-size:17px
  }
}

@media (min-width: 1025px) {
  /* Aturan CSS untuk tampilan desktop */
}

</style>