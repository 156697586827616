import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: { title: 'Home' }
  },
  {
    path: '/beranda',
    name: 'beranda',
    component: () => import(/* webpackChunkName: "about" */ '../views/BerandaView.vue'),
    meta: { title: 'Beranda' }
  },
  {
    path: '/listmenu',
    name: 'menu',
    component: () => import(/* webpackChunkName: "about" */ '../views/MenuView.vue'),
    meta: { title: 'Menu' }
  },
  {
    path: '/daftarmenu',
    name: 'daftarmenu',
    component: () => import(/* webpackChunkName: "about" */ '../views/MenuIndoView.vue'),
    meta: { title: 'Menu' }
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue'),
    meta: { title: 'About' }
  },
  {
    path: '/tentang',
    name: 'tentang',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/TentangView.vue'),
    meta: { title: 'Tentang' }
  },
  {
    path: '/projects',
    name: 'projects',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/ProjectView.vue'),
    meta: { title: 'Projects' }
  },
  {
    path: '/proyek',
    name: 'proyek',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/ProyekView.vue'),
    meta: { title: 'Proyek' }
  },
  {
    path: '/contact',
    name: 'contact',
    component: () => import(/* webpackChunkName: "about" */ '../views/ContactView.vue'),
    meta: { title: 'Contact' }
  },
  {
    path: '/kontak',
    name: 'kontak',
    component: () => import(/* webpackChunkName: "about" */ '../views/KontakView.vue'),
    meta: { title: 'Kontak' }
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  document.title = to.meta.title + " | Farah Fitriavida A's Portofolios"; 
  next();
});

export default router
