<template>
    <main
    class="scroll-smooth pt-2 bg-gradient-to-br from-gray-100 from-50% via-[#36CFDC] to-blue-400 dark:from-[#283048] dark:from-30% dark:via-[#36CFDC] dark:to-[#014F93] min-h-screen">
    <div class="paper-effect">
        <section class="w-3/4 h-16 flex flex-row m-auto mt-60">
            <div class="basis-1/9 ml-52 mr-3">
                <div class="w-6 h-6 mt-5 border-4 rounded-full  border-gray-300 border-t-indigo-300 animate-spin"></div>
            </div>
            <div class="basis-1/9 mr-3">
                <div class="w-8 h-8 mt-4 border-4 rounded-full  border-gray-300 border-t-indigo-300 animate-spin"></div>
            </div>
            <div class="basis-1/9 mr-3">
                <div class="w-12 h-12 mt-2 border-4 rounded-full  border-gray-300 border-t-indigo-300 animate-spin"></div>
            </div>
            <div class="basis-1/9 mr-3">
                <div class="w-20 h-20 -mt-3 border-4 rounded-full border-gray-300 border-t-indigo-300 animate-spin"></div>
            </div>
            <div class="basis-1/9 mr-3">
                <img src="../assets/img/farah-cartoon.jpeg" alt="" class="border-2 border-gray-300 border-t-indigo-300 animate-spin rounded-full drop-shadow-2xl w-24 -mt-5" style="animation-duration: 20s;">
            </div>
            <div class="basis-1/9 mr-3">
                <div class="w-20 h-20 -mt-3 border-4 rounded-full border-gray-300 border-t-indigo-300 animate-spin"></div>
            </div> 
            <div class="basis-1/9 mr-3">
                <div class="w-12 h-12 mt-2 border-4 rounded-full  border-gray-300 border-t-indigo-300 animate-spin"></div>
            </div>
            <div class="basis-1/9 mr-3">
                <div class="w-8 h-8 mt-4 border-4 rounded-full  border-gray-300 border-t-indigo-300 animate-spin"></div>
            </div>
            <div class="basis-1/9 mx-3">
                <div class="w-6 h-6 mt-5 border-4 rounded-full  border-gray-300 border-t-indigo-300 animate-spin"></div>
            </div>
        </section>
          <center>
              <div class="mt-8 w-1/4 bg-gradient-to-br from-[#075C9B] to-teal-200 rounded-md animate-pulse" >
                  <p class="p-1 font-semibold text-white mt-2 mb-2 ml-2">
                      Prepare the website..
                  </p>
                </div> 
          </center>
            <p class="text-center text-gray-600 mt-40" style="font-size:15px">Designed and coded by Farah Fitriavida A | ©️ 2023</p>
    </div>
    </main>
 </template>
 
 <script>
 export default {
   name: 'Preloader',
 }
 </script>

<style scoped>
/* Tambahkan definisi kelas untuk efek lembaran kertas di sini */
.paper-effect {
  transform: translate(0, 0); /* Translasi elemen ke atas */
  animation: slide-in 1s ease-in-out; /* Animasi transisi */
}

@keyframes slide-in {
  0% {
    transform: translate(0, -50px);
    opacity: 0;
  }
  100% {
    transform: translate(0, 0);
    opacity: 1;
  }
}
</style>